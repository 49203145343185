import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1ee65b02 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _350fb94e = () => interopDefault(import('../pages/career.vue' /* webpackChunkName: "pages/career" */))
const _a9e39e5c = () => interopDefault(import('../pages/career/_position.vue' /* webpackChunkName: "pages/career/_position" */))
const _013876d5 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _1ef13194 = () => interopDefault(import('../pages/newsletter.vue' /* webpackChunkName: "pages/newsletter" */))
const _8cc9d11c = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _16aa8bee = () => interopDefault(import('../pages/selected-works/index.vue' /* webpackChunkName: "pages/selected-works/index" */))
const _1a66910e = () => interopDefault(import('../pages/services.vue' /* webpackChunkName: "pages/services" */))
const _bc81d00a = () => interopDefault(import('../pages/team/index.vue' /* webpackChunkName: "pages/team/index" */))
const _4e45240c = () => interopDefault(import('../pages/selected-works/_work.vue' /* webpackChunkName: "pages/selected-works/_work" */))
const _0e278b22 = () => interopDefault(import('../pages/team/_member.vue' /* webpackChunkName: "pages/team/_member" */))
const _3e9e35c7 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _1ee65b02,
    name: "about"
  }, {
    path: "/career",
    component: _350fb94e,
    name: "career",
    children: [{
      path: ":position?",
      component: _a9e39e5c,
      name: "career-position"
    }]
  }, {
    path: "/contact",
    component: _013876d5,
    name: "contact"
  }, {
    path: "/newsletter",
    component: _1ef13194,
    name: "newsletter"
  }, {
    path: "/privacy-policy",
    component: _8cc9d11c,
    name: "privacy-policy"
  }, {
    path: "/selected-works",
    component: _16aa8bee,
    name: "selected-works"
  }, {
    path: "/services",
    component: _1a66910e,
    name: "services"
  }, {
    path: "/team",
    component: _bc81d00a,
    name: "team"
  }, {
    path: "/selected-works/:work?",
    component: _4e45240c,
    name: "selected-works-work"
  }, {
    path: "/team/:member",
    component: _0e278b22,
    name: "team-member"
  }, {
    path: "/",
    component: _3e9e35c7,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
